'use client';
import { CacheProvider } from '@chakra-ui/next-js';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';

import demuzoTheme from '../shared/ui-kit/theme';

import { PlayerProvider } from '@/features/audio-player';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SessionProvider } from 'next-auth/react';
import {
  ibmPlexMono,
  ibmPlexSans,
  naNMetrifyAWide,
} from '@/shared/fonts/fonts';
import type { Session } from 'next-auth';
import { getQueryClient } from '@/shared/utils/get-query-client';
import { SESSION_MAX_AGE } from '@/shared/constants/auth-session';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';
import * as CookieConsent from 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './cc.css';
import { useEffect } from 'react';

export function Providers({
  children,
  session,
  messages,
  locale,
}: {
  children: React.ReactNode;
  session: Session | null;
  locale: string;
  messages: AbstractIntlMessages;
}) {
  const queryClient = getQueryClient();

  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true, // this category is enabled by default
          readOnly: true, // this category cannot be disabled
        },
        analytics: {},
      },
      guiOptions: {
        consentModal: {
          layout: 'box inline',
          position: 'bottom center',
          flipButtons: false,
          equalWeightButtons: true,
        },
        preferencesModal: {
          layout: 'box',
          // position: 'left right',
          flipButtons: false,
          equalWeightButtons: true,
        },
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description:
                'To make the site work well, we use cookies and analytics services. By remaining on the page, you agree to the Cookie Processing Procedure (Privacy Policy, Section 7).',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              showPreferencesBtn: 'Manage Individual preferences',
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              acceptNecessaryBtn: 'Reject all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Strictly Necessary cookies',
                  description:
                    'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                  linkedCategory: 'necessary',
                },
                {
                  title: 'Performance and Analytics',
                  description:
                    'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                  linkedCategory: 'analytics',
                },
                {
                  title: 'More information',
                  description:
                    'To make the site work well, we use cookies and analytics services. By remaining on the page, you agree to the Cookie Processing Procedure (Privacy Policy, Section 7).',
                },
              ],
            },
          },
        },
      },
    });
  }, []);

  return (
    <NextIntlClientProvider
      locale={locale}
      messages={messages}
      timeZone={'Europe/Vienna'}
      defaultTranslationValues={{
        newLine: (chunks) => (
          <>
            <br />
            {chunks}
          </>
        ),
      }}
    >
      <SessionProvider
        session={session || undefined}
        refetchInterval={SESSION_MAX_AGE}
      >
        <QueryClientProvider client={queryClient}>
          <CacheProvider>
            <ChakraProvider
              theme={demuzoTheme(
                ibmPlexSans.style.fontFamily,
                naNMetrifyAWide.style.fontFamily,
                ibmPlexMono.style.fontFamily,
              )}
            >
              <CSSReset />
              <PlayerProvider>{children}</PlayerProvider>
            </ChakraProvider>
          </CacheProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SessionProvider>
    </NextIntlClientProvider>
  );
}
