import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  header: ReactNode;
  onCloseMenu: () => void;
  isMenuOpen: boolean;
};

export const MenuBurger: FC<Props> = ({
  onCloseMenu,
  children,
  isMenuOpen,
  header,
}) => {
  const t = useTranslations();

  return (
    <Box>
      <Box
        as={Modal}
        autoFocus={false}
        onClose={onCloseMenu}
        size='full'
        isOpen={isMenuOpen}
        variant='burgerStyle'
      >
        <ModalOverlay />
        <ModalContent
          bg='background.bg-black'
          p='20px'
        >
          <ModalHeader p='65px 0 0'>{header}</ModalHeader>

          <ModalBody
            as={'nav'}
            display='flex'
            flexDir='column'
            justifyContent='flex-end'
            gap='20px'
            p='40px 0 0'
          >
            {children}
          </ModalBody>

          <ModalFooter
            mt='20px'
            p='20px 0 0'
            justifyContent='start'
          >
            <Text
              textStyle='text-regular-xs'
              color='states.text&icon.disabled'
            >
              {t('MenuBurger.copyright')}
            </Text>
          </ModalFooter>
        </ModalContent>
      </Box>
    </Box>
  );
};

export default MenuBurger;
