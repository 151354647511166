'use client';

import { ReactNode, useEffect, useRef, useState } from 'react';
import { signIn } from 'next-auth/react';
import { useAlert } from '@ui-kit/hooks/useAlert';
import { useGlobalErrors } from '@/entities/global-error/use-global-error';
import { useQueryClient } from '@tanstack/react-query';
import { AbsoluteCenter, Box, Button, Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import * as Sentry from '@sentry/nextjs';
import { matchErrorsWithResolvers } from '@/shared/api/utils';

type Props = {
  children: ReactNode;
};

type BlockUiPayload = {
  title: string;
  helpText: string;
  description: string;
  handleRetry: () => void;
};

export const GlobalErrorGuard = ({ children }: Props) => {
  const t = useTranslations();
  const [globalErrors, resolveError] = useGlobalErrors();
  const errorMessage = useAlert({ position: 'bottom-left', status: 'error' });
  const [blockUiError, setBlockUiError] = useState<BlockUiPayload>();

  const isSignIn = useRef(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    globalErrors
      .filter(({ isResolved }) => !isResolved)
      .forEach(({ error, id }) => {
        matchErrorsWithResolvers(error)({
          AUTHORIZATION_ERROR: () => {
            queryClient.cancelQueries().then(async () => {
              if (!isSignIn.current) {
                isSignIn.current = true;
                resolveError(id);
                await signIn('keycloak');
              }
            });
          },
          BLOCK_USER_ACTIONS_ERROR: (e) => {
            setBlockUiError({
              title: e.title,
              description: e.message,
              helpText: e.helpText,
              handleRetry: () => {
                setBlockUiError(undefined);
                resolveError(id);
              },
            });
            Sentry.captureMessage('The user is shown a black screen', {
              level: 'fatal',
              requestSession: { status: 'crashed' },
            });
          },
          NOTIFICATION_ERROR: (e) => {
            errorMessage({
              description: e.message,
            });
            resolveError(id);
            Sentry.captureMessage('NOTIFICATION_ERROR', {
              level: 'error',
              extra: {
                ...e,
              },
              requestSession: { status: 'errored' },
            });
          },
          default: (e) => {
            Sentry.captureMessage('UNKNOWN_ERROR', {
              level: 'error',
              extra: {
                ...e,
              },
              requestSession: { status: 'errored' },
            });
            console.error(e);
          },
        });
      });
  }, [errorMessage, globalErrors, queryClient, resolveError]);

  if (blockUiError) {
    return (
      <Flex
        minH='100vh'
        w='full'
      >
        <AbsoluteCenter>
          <Text
            textStyle='heading-large-4xl'
            mb='20px'
            textAlign='center'
          >
            {blockUiError.title}
          </Text>
          <Box textAlign='center'>
            <Text
              textStyle='heading-large-lg'
              mb='20px'
            >
              {blockUiError.description}
            </Text>
            <Text
              textStyle='heading-regular-md'
              color='gray'
              mb='20px'
            >
              {blockUiError.helpText}
            </Text>
          </Box>
          <Box textAlign='center'>
            <Button
              variant='outline'
              onClick={blockUiError.handleRetry}
            >
              {t('NotFound.retry')}
            </Button>
          </Box>
        </AbsoluteCenter>
      </Flex>
    );
  }

  return <>{children}</>;
};

export default GlobalErrorGuard;
